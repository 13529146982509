import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import { GlobalContainer } from '../container/GlobalContainer'
import { theme } from '../theme'
import { TitleGradientHome } from '../title'
import { Text } from '../text'
import Dashboard from '../../images/dashboard_hospital.png'
import { device } from '../../devices'

export function HospitalBlock() {
  return <ContainerWithBg>
    <Image style={{
      position: 'absolute',
      top: 0,
      right: 0,
      width: '90vw',
      height: 'auto',
      objectFit: 'contain',
      zIndex: -1,
    }}/>
    <GlobalContainer css={css`align-self: inherit`}>
      <BlockTitle data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
        <TitleGradientHome color={theme.white}>
          <FormattedMessage id="paas_content.paas_hospital.title"/>
        </TitleGradientHome>
        <Text>
          Coming soon
        </Text>
      </BlockTitle>
      <BlockGeneration>
        <img src={Dashboard}/>
      </BlockGeneration>
    </GlobalContainer>
  </ContainerWithBg>
}

const BlockGeneration = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 6vw;
  
  img {
    width: 73.813vw;
    height: auto;
    
  }
  @media ${device.mobileL} {
    img {
      width: 100%;
      height: auto;
      padding: 20px 0 100px;
    }
    margin-top: 0;
  }
`

const BlockTitle = styled.div`
  margin: 15vw 0 0 23vw;
  
  p {
    color: ${(props) => props.theme.white};
    width: 29vw;
    margin-top:2vw;
    text-align:center;
  }
  
  @media ${device.mobileL} {
    margin: 50px 0 0 0;
  }
`

const Image = ({style}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background_paas_hospital.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img css={css`@media${device.mobileL} {width: 100% !important;}`} style={{...style}} fluid={data.file.childImageSharp.fluid}/>
}

const ContainerWithBg = styled.div`
    position: relative;
    margin-top: -7vw;
    width:100%;
    min-height: 69vw;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    flex-direction: column;
    
    @media ${device.mobileL} {
      margin-top: 140px;
      min-height: 400px;
      
      .gatsby-image-wrapper img {width:100% !important; height:300px;}
      
      h2, p {width:100% !important;text-align:center;}
    }
`
