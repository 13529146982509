import React from 'react'
import {useIntl} from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {HeroPaas} from '../components/hero/hero.paas'
import {Paas} from '../components/paas'

const PaasPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "title" })}
      />
      <HeroPaas intl={intl} />
      <Paas intl={intl} />
    </Layout>
  )
}
export default PaasPage
