import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import {FormattedMessage} from 'gatsby-plugin-intl'
import {GlobalContainer} from '../container/GlobalContainer'
import {graphql, useStaticQuery} from 'gatsby'
import {css} from '@emotion/core'
import {TitleGradientHome} from '../title'
import {Text} from '../text'
import {ButtonGradient} from '../buttons/button'
import {device} from '../../devices'

export function YourPaas() {
  return <ContainerWithBg >
    <Image style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '93vw',
      height: 'auto',
      objectFit: 'contain',
      zIndex: -1,
    }} css={css`@media ${device.mobileL} {display:none;}`}/>
    <GlobalContainer css={css`align-self: inherit;@media${device.mobileL} {opacity:1}`} data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
      <BlockTitle>
        <a href={`/en/#contact-us`} id="contact-us-mobile"
           css={css`display:none; @media ${device.mobileL} {display:block;color:#fff;p{-webkit-text-fill-color:inherit;}}`}>
          <ButtonGradient>
            <Text>
              <FormattedMessage id="paas_content.your_paas.contact"/>
            </Text>
          </ButtonGradient>
        </a>
        <Text>
          <FormattedMessage id="paas_content.your_paas.subtitle"/>
          <a href={`/en/#contact-us`} id="contact-us-desktop" css={css`@media ${device.mobileL} {display:none;}`}>
            <ButtonGradient>
              <Text>
                <FormattedMessage id="paas_content.your_paas.contact"/>
              </Text>
            </ButtonGradient>
          </a>
        </Text>
        <TitleGradientHome>
          <FormattedMessage id="paas_content.your_paas.title"/>
        </TitleGradientHome>
      </BlockTitle>
    </GlobalContainer>
  </ContainerWithBg>
}

const BlockTitle = styled.div`
  display: flex;
  font-size: 3.711vw;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 14.160vw;

  p {
    max-width: 18.2vw;
    background: ${({color}) => color ? '' : 'linear-gradient(91deg, #0000ff -1%, #0ccdfe 51%, #66ffe5 128%)'};
     -webkit-background-clip: text;
     -webkit-text-fill-color: ${({color}) => color ? '' : 'transparent'};
    button {
      width: 18vw;
      margin-top: 2.734vw;
      p {
        color: #fff;
        -webkit-text-fill-color: #fff;
        background: transparent;
      }
    }
  }

  @media ${device.mobileL} {
    margin-top: 0;
    padding: 0px 0 200px;
    flex-direction: column-reverse;

    p {
      max-width: 100%;
      font-size: 20px !important;
      button {
        width: auto;
      }
    }
  }

`

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "background_your_paas.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img {...props} fluid={data.file.childImageSharp.fluid}/>
}

const ContainerWithBg = styled.div`
    position: relative;
    width:100%;
    min-height: 44.922vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    @media ${device.mobileL} {
      min-height: auto;
      height: auto;

      p {text-align:center;margin: 30px 0;}
      button {
        height: auto; 
      p {
        margin:0;
        
        color: #fff;
        -webkit-text-fill-color: initial;
        }
      }
    }
`
