import React from 'react'
import {Global, LawyerBlock, LawyerBlockMobile} from './lawyers'
import {HospitalBlock} from './hospital'
import {YourPaas} from './yourpaas'
import {css} from '@emotion/core'
import {device} from '../../devices'

export const Paas = ({intl}) => {
  return (
    <Global css={css`
        #mobilePaas {
          display:none;
        }
        
        @media ${device.mobileL} {
          #desktopPaas {display:none;}
          #mobilePaas {
            display:flex;
          }
        }
    `}>
      <LawyerBlock intl={intl} />
      <LawyerBlockMobile intl={intl}/>
      <HospitalBlock />
      <YourPaas intl={intl}/>
    </Global>
  )
}
