import React from 'react'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import styled from '@emotion/styled'
import {css} from '@emotion/core'
import {GlobalContainer} from '../container/GlobalContainer'
import {ButtonGradient} from '../buttons/button'
import {device} from '../../devices'

export function scrollToNextSection() {
  return window?.scrollTo(0, window?.innerHeight - 200)
}

export const HeroPaas = () => {
  const intl = useIntl()
  return (
    <GlobalContainer css={css`@media${device.mobileL} {
    min-height: 65vh; h1 {margin-top: 16vw;}`}>
      <ContainerHero data-sal="slide-up" data-sal-duration="500" data-sal-easing="ease">
        <TitleHero dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'paas_content.hero_title'})}}/>
        <ButtonGradient onClick={scrollToNextSection} css={css`margin-top: 23px;`}><FormattedMessage id="paas_content.hero_button"/></ButtonGradient>
      </ContainerHero>
    </GlobalContainer>
  )
}

const ContainerHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 9vw;
  
  @media ${device.mobileL} {
    margin:0;
  }
`

const TitleHero = styled.h1`
  font-size: 2.734vw;
  text-align: left;
  letter-spacing: -0.005vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-top: 4.980vw;
  width: 66.113vw;
  
  b {
    color: ${({theme}) => theme.primary};
    font-weight: bold;
  }
  
  @media ${device.mobileL} {
    font-size: 194%;
    line-height: 46px;
    width: 100%;
  }
`
